import { attachDynamicEvent } from '~/common/helpers/dom'
import { updateCartCount } from '../../../site/pages/cart/common'
import { appendCsrfParams } from '~/common/helpers/csrf'
import { processResponse } from '../../../site/behaviors/remote/process_response'

function addToCart(event) {
  event.preventDefault()

  let link = this
  let productId = link.dataset.simpleroProductId
  let addToCartUrl = link.dataset.simpleroAddToCartUrl

  let data = new URLSearchParams({
    product_id: productId,
  })

  let isolator = link.closest('.product-attr-isolator, body')

  let quantity = isolator.querySelector(
    'input[name="product[quantity]"]'
  )?.value
  if (quantity) data.append('quantity', quantity)

  let priceId = isolator.querySelector(
    'select[name="product[price_id]"]'
  )?.value
  if (priceId) data.append('price_id', priceId)

  appendCsrfParams(data)

  link.classList.add('btn--loading')

  fetch(addToCartUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: data,
  })
    .then((response) => response.json())
    .then((json) => {
      processResponse(json)
      if (!json.redirect) {
        // Not using the normal stuff here as we need to scope it to the link that was clicked.
        if (json.updateLink) {
          link.innerHTML = json.updateLink.text
          link.removeAttribute('data-simplero-add-to-cart')
          link.setAttribute('href', json.updateLink.href)
        }

        link.classList.remove('btn--loading')
        updateCartCount(json.cartCount)
        isolator
          .querySelectorAll(
            '.sales-page-modern__price-switcher, .sales-page-modern__quantity'
          )
          .forEach((el) => {
            el.style.display = 'none'
          })
      }
      if (json.pixel) {
        eval(json.pixel)
      }
    })
}

window.addEventListener('DOMContentLoaded', () => {
  attachDynamicEvent('[data-simplero-add-to-cart]', 'click', addToCart)
})
